import React from 'react'
// import { VideoCheck } from '../server/api'
import { message, Upload, Modal, Button } from 'antd'
import 'antd/dist/antd.css'
import './index.scss'


class PicturesWall extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    // fileList: [],
  }

  handlePreview = file => this.props.onPreview && this.props.onPreview(file)

  handleCancel = () => this.setState({ previewVisible: false })

  handleChange = ({ fileList }) => {
    let res = fileList.filter(item => {
      const isImage = /(?:jpg|png|jpeg)$/i.test(item.type)
      if (!isImage) {
        message.info('只能上传图片')
      }
      return isImage
    })

    this.props.setResultData(res.slice(0, this.props.maxCount))
  }

  handleBeforeUpload = () => {
    return false
  }

  render() {
    const uploadButton = (
      <div>
        <img
          src={require('../assets/image/add@2x.png')}
          alt=""
          style={{ width: '16px', height: '16px' }}
        />
      </div>
    )
    const uploadWrap = (
      <div className="ant-upload-drag">
        <p className="ant-upload-drag-icon">
          <img src={require('../assets/image/circle@2x.png')} alt="" />
        </p>
        <p className="ant-upload-text">请将识别的图片拖拽至此区域</p>
        <p className="ant-upload-hint">支持人脸识别、色情识别、二维码识别</p>
        <Button type="primary">选择图片</Button>
      </div>
    )
    return (
      <>
        <Upload
          listType="picture-card"
          accept="image/*"
          fileList={this.props.resultData}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          multiple={true}
          beforeUpload={this.handleBeforeUpload}
        >
          {this.props.resultData.length === 0
            ? uploadWrap
            : this.props.resultData.length >= this.props.maxCount
            ? null
            : uploadButton}
        </Upload>
      </>
    )
  }
}
export default PicturesWall
