import React, { useEffect, useState, useRef } from 'react'
import { Upload, Button, Table, Input, message, Carousel } from 'antd'
import PicturesWall from '../../component/pictureWall'

import 'antd/dist/antd.css'
import './index.scss'
import axios from 'axios'
import { baseUrl } from '../../server/baseUrl'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const { Search } = Input
const maxCount = 12

//主组件
const { Dragger } = Upload
function ImageAudit(props) {
  const [flag, setFlag] = useState(false)
  const [resultData, setResultData] = useState([])
  const [checkResult, setCheckResult] = useState([])
  const [showCarousel, setShowCarousel] = useState(false)
  const [currentResultData, setCurrentResultData] = useState({})
  const [imageStyle, setImageStyle] = useState({
    width: 'auto',
  })
  const models = {
    face: true,
    pron: true,
    text: true,
    flag: true,
  }

  // let id = window.localStorage.getItem('userId')
  // console.log(id)

  let uploadImage = () => {
    let array = []
    let promiseArr = resultData.map(item => {
      return getBase64(item.originFileObj).then(file64 => {
        return axios.post(
          `${baseUrl}/upload_base64`,
          {
            image: file64,
            models: models,
          },
          {
            withCredentials: true,
          }
          // {
          //   headers: {
          //     Cookie: `user_id=${id}`,
          //   },
          // }
        )
      })
    })

    Promise.all(promiseArr).then(data => {
      data.forEach((res, i) => {
        if (res.data && res.data.code == 200) {
          let result = res.data?.data
          // console.debug('result.image<', result.image)
          resultData[i].url = result.image
          array.push({
            ...result,
            name: resultData[i].name,
            __index__: i,
            uid: resultData[i].uid,
            // thumbUrl: resultData[i].thumbUrl,
            thumbUrl: result.image,
          })
        }
      })
      setCheckResult(array)
      setCurrentResultData(array[0] || {})
    })
  }
  let transformArrayBufferToBase64 = buffer => {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  let getFilename = url => {
    let http = url.split('?')[0]
    let file = http.split('/')
    return file[file.length - 1]
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    //转换成file对象
    return new File([u8arr], filename, { type: mime })
  }

  useEffect(() => {
    uploadImage()
  }, [resultData])

  const recoItems = [
    {
      id: 'name',
      title: '图片名称',
      color: 'rgba(0,0,0,0)',
    },
    {
      id: 'face',
      title: '人脸识别',
      color: '#F43232',
    },
    {
      id: 'pron',
      title: '色情识别',
      color: '#FFA042',
    },
    {
      id: 'flag',
      title: '标识识别',
      color: '#3CC03C',
    },
    {
      id: 'text',
      title: '文字识别',
      color: '#556DF7',
    },
  ]
  const columns = [
    {
      title: (
        <div
          className="info-item"
          key={recoItems[0].id}
          style={{ background: '#fff' }}
        >
          <div className="title">{recoItems[0].title}</div>
          <div
            className="icon"
            style={{ background: recoItems[0].color }}
          ></div>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text, item) => {
        // console.debug('currentResultData item<', item)
        const hasUid =
          item && item.uid && currentResultData && currentResultData.uid

        return (
          <span
            style={{
              width: '150px',
              color:
                hasUid && item.uid === currentResultData.uid ? '#3d7fff' : '',
            }}
          >
            {text.length > 15 ? text.slice(0, 15) + '...' : text}
          </span>
        )
      },
    },
    {
      title: (
        <div
          className="info-item"
          key={recoItems[1].id}
          style={{ background: '#fff' }}
        >
          <div className="title">{recoItems[1].title}</div>
          <div
            className="icon"
            style={{ background: recoItems[1].color }}
          ></div>
        </div>
      ),
      dataIndex: 'face',
      key: 'face',
      render: (text, data) => {
        let array = data.face
        const hasUid =
          data && data.uid && currentResultData && currentResultData.uid
        return (
          <div
            className="res-box"
            style={{
              color:
                hasUid && data.uid === currentResultData.uid ? '#3d7fff' : '',
            }}
          >
            {array.map(item => {
              return (
                <div className="box-item">
                  <div className="item-name">{item.label}</div>
                  <div className="item-number">{item.confidence}</div>
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      title: (
        <div
          className="info-item"
          key={recoItems[2].id}
          style={{ background: '#fff' }}
        >
          <div className="title">{recoItems[2].title}</div>
          <div
            className="icon"
            style={{ background: recoItems[2].color }}
          ></div>
        </div>
      ),
      dataIndex: 'porn',
      key: 'porn',
      render: (text, data) => {
        let array = data.pron
        const hasUid =
          data && data.uid && currentResultData && currentResultData.uid
        return (
          <div
            className="res-box"
            style={{
              color:
                hasUid && data.uid === currentResultData.uid ? '#3d7fff' : '',
            }}
          >
            {array.map(item => {
              return (
                <div className="box-item">
                  <div className="item-name">{item.label}</div>
                  <div className="item-number">{item.confidence}</div>
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      title: (
        <div
          className="info-item"
          key={recoItems[3].id}
          style={{ background: '#fff' }}
        >
          <div className="title">{recoItems[3].title}</div>
          <div
            className="icon"
            style={{ background: recoItems[3].color }}
          ></div>
        </div>
      ),
      dataIndex: 'flag',
      key: 'flag',
      render: (text, data) => {
        let array = data.flag
        const hasUid =
          data && data.uid && currentResultData && currentResultData.uid
        return (
          <div
            className="res-box"
            style={{
              color:
                hasUid && data.uid === currentResultData.uid ? '#3d7fff' : '',
            }}
          >
            {array.map(item => {
              return (
                <div className="box-item">
                  <div className="item-name">{item.label}</div>
                  <div className="item-number">{item.confidence}</div>
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      title: (
        <div
          className="info-item"
          key={recoItems[4].id}
          style={{ background: '#fff' }}
        >
          <div className="title">{recoItems[4].title}</div>
          <div
            className="icon"
            style={{ background: recoItems[4].color }}
          ></div>
        </div>
      ),
      dataIndex: 'text',
      key: 'text',
      render: (text, data) => {
        let array = data.text
        return (
          <div
            className="res-box"
            style={{
              color: data.uid === currentResultData.uid ? '#3d7fff' : '',
            }}
          >
            {array.map(item => {
              return (
                <div className="box-item">
                  <div className="item-name">{item.label}</div>
                  <div className="item-number">{item.confidence}</div>
                </div>
              )
            })}
          </div>
        )
      },
    },
  ]
  // console.log(resultData, checkResult)

  const carouselRef = useRef(null)
  const renderImgCarousel = () => {
    let hidden = false
    if (!Array.isArray(resultData) || resultData.length < 1) {
      hidden = true
    }

    if (!showCarousel) {
      hidden = true
    }

    // console.debug('resultData<', resultData)
    return (
      <div
        className="uploadImg-carousel-container"
        onClick={() => setShowCarousel(false)}
        style={{ display: hidden ? 'none' : 'block' }}
      >
        <div className="uploadImg-carousel-wrapper">
          <Carousel
            ref={carouselRef}
            className="uploadImg-carousel"
            dots={false}
            // beforeChange={index => {
            //   // 初始设置
            //   setCurrentResultData(resultData[index])
            // }}
            afterChange={index => {
              setCurrentResultData(resultData[index])
            }}
          >
            {resultData.map((file, idx) => {
              console.debug('resultData file<', file)
              return (
                <img
                  className="carousel-img"
                  key={idx}
                  // onLoad={e => {
                  //   const { width, height } = e.target
                  //   if (width > height) {
                  //     // e.target.style = "width: 'auto'; height: '100%'"
                  //     e.target.className = 'isWidth'
                  //   } else if (width < height) {
                  //     // e.target.style = "width: 'auto'; height: '100%'"
                  //     // e.target.style = "width: '100%'; height: 'auto'"
                  //     e.target.className = 'isHeight'
                  //   }

                  //   console.debug('img e<', e, width, height)
                  // }}
                  src={file.url || file.thumbUrl}
                  // style={{ ...imageStyle }}
                />
              )
              // return <span>{file.thumbUrl}</span>
            })}
          </Carousel>
          <span
            className="switch-btn switch-prev"
            onClick={e => {
              if (carouselRef.current) {
                carouselRef.current.prev()
              }
              e.stopPropagation()
            }}
          >
            &lt;
          </span>
          <span
            className="switch-btn switch-next"
            onClick={e => {
              if (carouselRef.current) {
                carouselRef.current.next()
              }
              e.stopPropagation()
            }}
          >
            &gt;
          </span>
        </div>
      </div>
    )

    // if (!Array.isArray(checkResult) || checkResult.length < 1) {
    //   return null
    // }

    // return (
    //   <Carousel className="uploadImg-carousel" dots={false}>
    //     {checkResult.map(file => {
    //       return <img src={file.themeUrl} />
    //     })}
    //   </Carousel>
    // )
  }

  return (
    <div className="img-audit">
      <div className="upload">
        <h3 className="sub-title">识别内容上传区域</h3>
        <div className="sub-content">
          <div id="uploadImg">
            <PicturesWall
              maxCount={maxCount}
              resultData={resultData}
              setResultData={setResultData}
              models={models}
              onPreview={file => {
                // console.debug('onPreview file<', file)

                resultData.forEach((result, idx) => {
                  if (result.uid === file.uid) {
                    carouselRef.current.goTo(idx)
                    setCurrentResultData(result)
                    return false
                  }
                })

                setShowCarousel(true)
              }}
            ></PicturesWall>
            {renderImgCarousel()}
          </div>

          <div className="content-search">
            {/* <div className="upload-image">
              <Button
                style={{ width: '120px', height: '40px' }}
                type={'primary'}
                onClick={() => uploadImage()}
              >
                开始检测
              </Button>
            </div> */}
            <div className="upload-url">
              <Search
                placeholder="请输入图片URL"
                enterButton="检测网络图片"
                size="large"
                onSearch={value => {
                  if (value == '') return

                  let fileName = getFilename(value)
                  let arr = fileName.split('.')
                  let postfix = arr[arr.length - 1]
                  if (resultData.length >= 5) {
                    return
                  }
                  if (postfix == 'gif') {
                    message.info('不支持gif图片')
                    return
                  }
                  axios
                    .get(value, { responseType: 'arraybuffer' })
                    .then(res => {
                      let img = transformArrayBufferToBase64(res.data)
                      let base64 = `data:image/jpeg;base64,${img}`

                      var file = dataURLtoFile(base64, fileName)
                      let id = 'wfgjergnregmnerg' + Math.random()
                      file.uid = id

                      let fileObj = {
                        error: '',
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        name: fileName,
                        originFileObj: file,
                        percent: 100,
                        response:
                          '<!DOCTYPE html>↵<html lang="en">↵<head>↵<meta charset="utf-8">↵<title>Error</title>↵</head>↵<body>↵<pre>Cannot POST /image</pre>↵</body>↵</html>↵',
                        size: file.size,
                        status: 'success',
                        thumbUrl: base64,
                        type: file.type,
                        uid: id,
                      }
                      let arr = [...resultData]
                      arr.push(fileObj)
                      setResultData(arr)
                    })
                }}
              />
              <Button
                style={{ width: '120px', marginLeft: '20px', height: '40px' }}
                type={'primary'}
                onClick={() => {
                  if (resultData.length >= maxCount) {
                    return
                  }
                  let fileName = '随机图片.jpg'
                  axios
                    .get(`${baseUrl}/random`, {
                      withCredentials: true,
                    })
                    .then(res => {
                      if (res.data) {
                        var file = dataURLtoFile(res.data, fileName)
                        let id = 'wfejwfjwgjoiewrg' + Math.random()
                        file.uid = id
                        console.log(file)

                        let fileObj = {
                          error: '',
                          lastModified: file.lastModified,
                          lastModifiedDate: file.lastModifiedDate,
                          name: fileName,
                          originFileObj: file,
                          percent: 100,
                          response:
                            '<!DOCTYPE html>↵<html lang="en">↵<head>↵<meta charset="utf-8">↵<title>Error</title>↵</head>↵<body>↵<pre>Cannot POST /image</pre>↵</body>↵</html>↵',
                          size: file.size,
                          status: 'success',
                          thumbUrl: res.data,
                          type: file.type,
                          uid: id,
                        }
                        let arr = [...resultData]
                        arr.push(fileObj)
                        setResultData(arr)
                      }
                    })
                }}
              >
                随机添加图片
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="result">
        <h3 className="sub-title">识别结果展示区域</h3>
        <div className="infoItem">
          <Table
            columns={columns}
            dataSource={checkResult}
            footer={() => {
              return <></>
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default ImageAudit
