import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Upload, Button, Input, Switch, Space, Radio } from 'antd'
import { baseUrl } from '../../server/baseUrl'
import 'antd/dist/antd.css'
import './index.scss'
import { getM3u8Frame, switchM3u8FrameChannel } from './service'

const pre = 'data:image/png;base64,'

function LiveVideo(props) {
  const myRef = useRef({ timer: 0 })
  const imgRef = useRef()

  const [currentLine, setCurrentLine] = useState(0)
  const [stop, setStop] = useState(false)

  const radioOptions = [
    { label: '线路一', value: 0 },
    { label: '线路二', value: 1 },
    { label: '线路三', value: 2 },
  ]

  // 改变线路
  const onChangeLine = () => {}

  const clearTimer = () => {
    if (myRef.current.timer) {
      clearTimeout(myRef.current.timer)
    }
  }

  const startTimer = (interval = 150) => {
    clearTimer()
    if (stop) {
      return
    }
    myRef.current.timer = setTimeout(fetchM3u8Image, interval)
  }

  // 获取结果
  const fetchM3u8Image = () => {
    if (stop) {
      clearTimer()
      return
    }
    getM3u8Frame()
      .then(data => {
        let base64 = ''
        if (data.image) {
          base64 = pre + data.image
          imgRef.current.src = base64
        }
        if (stop) {
          clearTimer()
          return ''
        }
        startTimer()
        return ''
      })
      .catch(() => {
        startTimer()
      })
  }
  useEffect(() => {
    if (stop) {
      clearTimer()
    } else {
      startTimer()
    }
  }, [stop])

  useEffect(() => {
    const navElement = document.getElementById('app-nav')
    if (navElement) {
      navElement.style.display = 'none'
    }

    startTimer()

    return () => {
      if (navElement) {
        navElement.style.display = 'block'
      }
      clearTimer()
    }
  }, [])

  useEffect(() => {
    switchM3u8FrameChannel(currentLine)
      .then(data => {
        if (data.code === 200) {
          console.debug('switch success')
        } else {
          console.debug('switch fail')
        }
      })
      .catch(() => {
        console.debug('switch fail')
      })
  }, [currentLine])

  return (
    <div className="live-video">
      <div className="upload">
        <h3 className="sub-title">直播视频审核</h3>
        <div className="sub-content">
          <div id="uploadVideo">
            <div id="show">
              <img className="frame-img" ref={imgRef} />
              {/* <canvas id="canvasBuf"></canvas>
              <canvas
                id="canvas"
                style={{ display: isShow.showCanvas ? 'block' : 'none' }}
              ></canvas>

              <video
                id="m3u8-video"
                className="video-js vjs-default-skin vjs-big-play-centered"
                controls
                preload="auto"
                data-setup="{}"
                style={{
                  width: '100%',

                  height: '100%',
                  position: 'absolute',
                  zIndex: 99,
                  right: 0,
                }}
              >
                <source
                  id="source"
                  src={m3u8URL}
                  type="application/x-mpegURL"
                />
              </video> */}
            </div>
          </div>
          <Space className="upload-url">
            <Radio.Group
              options={radioOptions}
              value={currentLine}
              onChange={e => setCurrentLine(e.target.value)}
            />
            {/* <Button
              onClick={() => {
                setStop(!stop)
              }}
            >
              {stop ? '开始' : '停止'}
            </Button> */}
          </Space>
        </div>
      </div>
    </div>
  )
}
export default LiveVideo
