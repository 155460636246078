let baseUrl
let homeUrl
let clientID
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    baseUrl = 'https://autochessintel-intelcdkapi-qa.dragonest.com'
    clientID = 'lyCZnSfLCoeeXSNrwk'
    break
  case 'qa':
    baseUrl = 'https://autochessintel-intelcdkapi-qa.dragonest.com'
    clientID = 'lyCZnSfLCoeeXSNrwk'
    break
  case 'prod':
    baseUrl = 'https://autochessintel-intelcdkapi.dragonest.com'
    clientID = 'lyqgCrDjyLwNnsIqFz'
    break
  default:
    // baseUrl = "https://autochessintel-intelcdkapi-qa.dragonest.com"; //本地
    // baseUrl='http://172.16.215.56:5001'; //?
    // baseUrl='http://system-demo.xingzheai.cn/api'
    clientID = 'lyCZnSfLCoeeXSNrwk'
    baseUrl = 'http://system-demo.xingzheai.cn/api'

    // baseUrl = "http://172.20.23.81:5000";
    break
}

export { baseUrl, clientID, homeUrl }
