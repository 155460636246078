import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Upload, Button, Switch } from 'antd'
import { baseUrl } from '../../server/baseUrl'
import 'antd/dist/antd.css'
import './index.scss'
let is_ok = true
let images = null
let current = 0
let timer = null
const { Dragger } = Upload
function VideoAudit(props) {
  let isGetResult = false
  const [context, setContext] = useState(null)
  const [isShow, setIsShow] = useState({
    showVideo: false,
    showCanvas: false,
  })
  const [contextBuf, setContextBuf] = useState(null)
  const [canvasBuf, setCanvasBuf] = useState(null)
  const [canvas, setCanvas] = useState(null)
  const [video, setVideo] = useState(null)
  const [show, setShow] = useState(null)
  const [models, setModels] = useState({
    face: true,
    pron: true,
    text: false,
    flag: false,
  })

  useEffect(() => {
    const canvasBuf = document.getElementById('canvasBuf')
    const contextBuf = canvasBuf.getContext('2d')
    const canvas = document.getElementById('canvas')
    const context = canvas.getContext('2d')
    const video = document.getElementById('video')
    const show = document.getElementById('show')

    let uploadBase64 = image => {
      let customModel = {
        ...models,
        face:
          document.getElementById('faceCheck').getAttribute('aria-checked') ==
          'true'
            ? true
            : false,
        pron:
          document.getElementById('pronCheck').getAttribute('aria-checked') ==
          'true'
            ? true
            : false,
      }
      console.log(customModel)
      let lineWidth = { face: 3, pron: 3, text: 3, flag: 3 }
      let lineColor = {
        face: '#F43232',
        pron: '#FFA042',
        text: '#556DF7',
        flag: '#3CC03C',
      }
      let fontSize = { face: 15, pron: 15, text: 15, flag: 15 }
      let fontColor = {
        face: '#F43232',
        pron: '#FFA042',
        text: '#556DF7',
        flag: '#3CC03C',
      }
      let json = { image: image, models: customModel }
      // console.log(models)
      let xhr = new XMLHttpRequest()
      xhr.open('POST', baseUrl + '/upload_base64')
      xhr.withCredentials = true
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.send(JSON.stringify(json))
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let result = JSON.parse(xhr.responseText)
          if (result.code === 400) {
            // window.location.href = '/video'
            return
          }
          setIsShow({ showCanvas: true, showVideo: false })
          // const image = result.data.image

          const imageElement = document.createElement('img')
          imageElement.src = result.data.image
          imageElement.onload = () => {
            const tmpCanvas = document.createElement('canvas')
            tmpCanvas.width = canvasBuf.width
            tmpCanvas.height = canvasBuf.height
            const tmpCanvasContext = tmpCanvas.getContext('2d')
            tmpCanvasContext.drawImage(
              imageElement,
              0,
              0,
              canvasBuf.width,
              canvasBuf.height
            )
            const image = tmpCanvasContext.getImageData(
              0,
              0,
              canvasBuf.width,
              canvasBuf.height
            )
            context.putImageData(image, 0, 0)

            // context.putImageData(images, 0, 0)
            for (let model in customModel) {
              drawModelLabel(
                context,
                result.data[model],
                lineWidth[model],
                lineColor[model],
                fontSize[model],
                fontColor[model]
              )
            }
            is_ok = true
          }
        }
      }
    }

    let drawModelLabel = (
      context,
      data,
      linewidth,
      lineColor,
      fontSize,
      fontColor
    ) => {
      for (let i = 0; i < data.length; i++) {
        let box = data[i].box
        let confidence = data[i].confidence
        let label = data[i].label
        drawLabel(
          context,
          box[0],
          box[1],
          box[2],
          box[3],
          linewidth,
          lineColor,
          label,
          confidence,
          fontSize,
          fontColor
        )
      }
    }

    let drawLabel = (
      context,
      x1,
      y1,
      x2,
      y2,
      lineWidth,
      lineColor,
      text,
      confidence,
      fontSize,
      fontColor
    ) => {
      let label = text + ' ' + confidence

      const extend = 4 + lineWidth / 2

      x1 = x1 < 5 * extend ? 5 * extend : x1
      y1 = y1 < 5 * extend ? 5 * extend : y1

      context.lineWidth = lineWidth
      context.strokeStyle = lineColor
      context.strokeRect(x1, y1, x2 - x1, y2 - y1)
      context.fillStyle = lineColor
      context.textAlign = 'start'
      context.textBaseline = 'bottom'
      context.font = `${fontSize}px bold 黑体`
      context.fillStyle = fontColor
      context.fillText(label, x1, y1 - lineWidth / 2)
    }

    function switchToCanvas() {
      if (video.ended && isGetResult == false) {
        isGetResult = true
        // window.location.href =
        //   '/video/manage' +
        //   `?isFaceCheck=${models.face}&isPronCheck=${models.pron}`
        // getManage().then(res => {
        //   // window.location.href =
        //   //   '/video/manage' +
        //   //   `?isFaceCheck=${models.face}&isPronCheck=${models.pron}`
        // })
      }
      // 将video上的图片的每一帧以图片的形式绘制的canvas上
      let video_time = video.currentTime
      if (current !== video_time && is_ok) {
        is_ok = false
        contextBuf.drawImage(video, 0, 0, canvasBuf.width, canvasBuf.height)
        images = contextBuf.getImageData(
          0,
          0,
          canvasBuf.width,
          canvasBuf.height
        )
        current = video_time
        uploadBase64(canvasBuf.toDataURL('image/jpeg'))
      }
      timer = window.requestAnimationFrame(switchToCanvas)
    }
    video.onplay = function () {
      if (video.src === '') return
      let frameHeight
      let frameWidth
      let rate
      if (
        (video.clientHeight / video.videoHeight) * video.videoWidth <=
        video.clientWidth
      ) {
        frameHeight = video.clientHeight
        frameWidth = (video.clientHeight / video.videoHeight) * video.videoWidth
        rate = (frameWidth / video.clientWidth) * 100
        canvasBuf.style.height = '100%'
        canvasBuf.style.width = `${rate}%`
        canvas.style.height = '100%'
        canvas.style.width = `${rate}%`
        canvas.style.left = `${(video.clientWidth - frameWidth) / 2}px`
        canvas.style.top = '0px'
      } else {
        frameWidth = video.clientWidth
        frameHeight = (video.clientWidth / video.videoWidth) * video.videoHeight
        rate = (frameHeight / video.clientHeight) * 100
        canvasBuf.style.height = `${rate}%`
        canvasBuf.style.width = '100%'
        canvas.style.height = `${rate}%`
        canvas.style.width = '100%'
        canvas.style.top = `${(video.clientHeight - frameHeight) / 2}px`
        canvas.style.left = '0px'
      }
      canvasBuf.height = frameHeight
      canvasBuf.width = frameWidth
      canvas.height = frameHeight
      canvas.width = frameWidth

      switchToCanvas()
    }
    setContext(context)
    setContextBuf(contextBuf)
    setCanvas(canvas)
    setCanvasBuf(canvasBuf)
    setVideo(video)
    setShow(show)
  }, [models])

  const uplodProps = {
    name: 'file',
    multiple: true,
    accept: 'video/*',
    beforeUpload(file) {
      video.pause()
      video.src = URL.createObjectURL(file)
      video.controls = 'controls'

      setIsShow({ showVideo: true, showCanvas: false })
      return false
    },
    onRemove: () => {
      video.src = ''
      // const canvas = document.getElementById('canvas')
      // canvas.style.width = 0
      // canvas.style.height = 0
      if (timer) {
        window.cancelAnimationFrame(timer)
      }
      setIsShow({ showVideo: false, showCanvas: false })
    },
  }
  useEffect(() => {
    deleteAllVideo()
  }, [])

  let deleteAllVideo = () => {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest()
      xhr.open('get', `${baseUrl}/manage/delete/all`)
      xhr.withCredentials = true
      xhr.send()
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve('')
          } else if (xhr.status == 400) {
            // window.location.href = '/'
          } else if (xhr.status == 500) {
            //这里排除 不存在的数据canvas
            resolve({
              data: null,
            })
          } else {
            reject('false')
          }
        }
      }
    })
  }
  function getManage() {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest()
      xhr.open('get', `${baseUrl}/manage`)
      xhr.withCredentials = true
      xhr.send()
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            resolve('')
          } else if (xhr.status == 400) {
            // window.location.href = '/'
          } else if (xhr.status == 500) {
            //这里排除 不存在的数据canvas
            resolve({
              data: null,
            })
          } else {
            reject('false')
          }
        }
      }
    })
  }
  return (
    <div className="video-audit">
      <div className="upload">
        <h3 className="sub-title">识别内容上传区域</h3>
        <div className="sub-content">
          <div id="uploadVideo">
            <div id="show">
              <canvas id="canvasBuf"></canvas>
              <canvas
                id="canvas"
                style={{ display: isShow.showCanvas ? 'block' : 'none' }}
              ></canvas>
              <video
                id="video"
                type="video/mp4,video/avi"
                style={{ display: isShow.showVideo ? 'block' : 'none' }}
              >
                浏览器不支持
              </video>
            </div>
            <Dragger {...uplodProps}>
              <p className="ant-upload-drag-icon">
                <img src={require('../../assets/image/circle@2x.png')} alt="" />
              </p>
              <p className="ant-upload-text">请将识别的视频拖拽至此区域</p>
              <p className="ant-upload-hint">
                支持人脸识别、色情识别、二维码识别
              </p>
              <Button type="primary">选择视频</Button>
            </Dragger>
          </div>
          <div className="foot-control">
            <Button
              type="primary"
              onClick={() => {
                if (timer) {
                  window.cancelAnimationFrame(timer)
                }
                getManage().then(res => {
                  window.location.href =
                    '/video/manage' +
                    `?isFaceCheck=${models.face}&isPronCheck=${models.pron}`
                })
              }}
            >
              检测结果
            </Button>
            <div className="checkbox">
              <span>人脸识别</span>
              <Switch
                id="faceCheck"
                defaultChecked={models.face}
                onChange={checked => {
                  setModels({ ...models, face: checked })
                }}
              ></Switch>
            </div>
            <div className="checkbox">
              <span>色情识别</span>
              <Switch
                id="pronCheck"
                defaultChecked={models.pron}
                onChange={checked => {
                  setModels({ ...models, pron: checked })
                }}
              ></Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VideoAudit
