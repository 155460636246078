import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Select, Slider, Button, Pagination, Modal, Checkbox } from 'antd'
import { ArrowsAltOutlined } from '@ant-design/icons'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './index.scss'
import { deleteAll, imagesNumber } from '../../../server/api'
import { baseUrl } from '../../../server/baseUrl'
import axios from 'axios'

let getRequest = () => {
  const url = window.location.search //获取url中"?"符后的字串
  let theRequest = new Object()
  if (url.indexOf('?') != -1) {
    let str = url.substr(1)
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

let currentIndex = 0

const { Option } = Select
function Manage() {
  // let images_count = 0;
  let result = getRequest()
  let isFaceCheck = result.isFaceCheck
  let isPronCheck = result.isPronCheck
  const [selectItem, setSelectItem] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ])
  const [images_count, setImages_count] = useState(-1)
  const [allResult, setAllResult] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isModalVisible, setIsModalVisible] = useState(true)
  const [totalNumber, setTotalNumber] = useState(0)
  const [totalPage, setTotalPage] = useState(-1)
  const [canvasTotal, setCanvasTotal] = useState([])
  const [faceArray, setFaceArray] = useState([0, 1])
  const [pronArray, setPronArray] = useState([0, 1])

  const [faceNum, setFaceNum] = useState(0)
  const [totalNum, setTotalNum] = useState(0)
  const [pronNum, setPronNum] = useState(0)

  const [currentType, setCurrentType] = useState('all')

  let page_count = 1
  let current_page = 1
  let page_size = 12
  let page_show_num = 9
  let start_num = 1

  // let [] = useState

  let models = { face: false, pron: false, text: false, flag: false }
  let lineWidth = { face: 2, pron: 2, text: 2, flag: 2 }
  let lineColor = {
    face: '#F43232',
    pron: '#FFA042',
    text: '#3CC03C',
    flag: '#556DF7',
  }
  let fontSize = { face: 15, pron: 15, text: 15, flag: 15 }
  let fontColor = {
    face: '#F43232',
    pron: '#FFA042',
    text: '#3CC03C',
    flag: '#556DF7',
  }

  function deleteAllImages() {
    console.log(selectItem, currentPage)
    let arr = selectItem
      .map((item, i) => {
        if (item && currentPage != -1) {
          let cur = (currentPage - 1) * 12
          return cur + i + 1
        }
        return null
      })
      .filter(item => {
        return item != void 0
      })
    console.log(arr)
    let imageStr = arr.join(',')
    axios
      .get(`${baseUrl}/manage/delete/images?image_nums=${imageStr}`, {
        withCredentials: true,
      })
      .then(res => {
        window.location.reload()
      })
  }
  function getOne(i, cur) {
    return new Promise(function (resolve, reject) {
      // let image_num = i;
      let image_num = cur
      let resize = 1
      let xhr = new XMLHttpRequest()
      xhr.open(
        'get',
        `${baseUrl}/manage/image?image_num=${image_num}&resize=${resize}`
      )
      xhr.withCredentials = true

      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send()
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            let result = JSON.parse(xhr.responseText)

            // let canvas = document.getElementById('image' + i)
            // initCanvas(canvas, result)
            // clearCanvas(canvasTotal)
            resolve(result)
          } else if (xhr.status == 400) {
            // window.location.href = '/'
          } else if (xhr.status == 500) {
            //这里排除 不存在的数据canvas
            resolve({
              data: null,
            })
          } else {
            reject('false')
          }
        }
      }
    })
  }
  function getList(cur, totalNum) {
    console.log(cur, totalNum, totalNumber, totalPage)
    let total = totalNum ? totalNum : totalNumber
    let num = 12
    if (cur == totalPage) {
      num = total - (cur - 1) * 12
    }
    var res = []
    console.log('total number :', num)
    for (var i = 1; i < num + 1; i++) {
      let curAjax = (cur - 1) * 12 + i
      res.push(getOne(i, curAjax))
    }
    return res
  }

  function getAll(data) {
    return Promise.all(data).then(function (res) {
      let arr = [...res]
      setAllResult(arr)

      for (let i = 1; i < 13; i++) {
        if (res[i - 1] && 'data' in res[i - 1]) {
          let canvas = document.getElementById('image' + i)
          canvas.parentNode.style.display = 'block'

          initCanvas(canvas, res[i - 1])
        } else {
          let canvas = document.getElementById('image' + i)
          canvas.parentNode.style.display = 'none'
        }
      }
      setCanvasTotal(res)
    })
  }

  function getToal(faceData, pronData, type) {
    let curType = type ? type : currentType
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest()

      xhr.open('post', `${baseUrl}/manage/images`)
      xhr.withCredentials = true
      xhr.setRequestHeader('Content-Type', 'application/json')
      let json = JSON.stringify({
        models: {
          face: curType == 'all' ? true : curType == 'face',
          pron: curType == 'all' ? true : curType == 'pron',
          text: false,
          flag: false,
        },
        confidence: {
          face: curType == 'all' ? [0, 1] : faceData ? faceData : faceArray,
          pron: curType == 'all' ? [0, 1] : pronData ? pronData : pronArray,
          text: [0, 1],
          flag: [0, 1],
        },
      })

      xhr.send(json)
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            let images_count = xhr.responseText
            // resolve(Math.ceil(images_count/12))
            resolve(xhr.responseText)
          } else if (xhr.status == 400) {
            reject('err')
          }
        }
      }
    })
  }

  function selectPage(e) {
    if (!current_page) {
      current_page = document.getElementsByClassName('active')[0]
    }
    if (current_page == e) return
    current_page.className = ''
    current_page = e
    console.log(e)
    e.className = 'active'
    setPage()
  }

  function setPage() {
    let image_num_start = (current_page.innerHTML - 1) * page_size + 1
    let image_num_end = image_num_start + page_size - 1
    if (image_num_end > images_count) image_num_end = images_count
    getImages(image_num_start, image_num_end, 0.3)
  }

  function pageBarPrev() {
    let start = start_num - page_show_num
    if (start < 0) start = 1
    initPageBar(start, 1)
  }

  function pageBarNext() {
    let start = start_num + page_show_num
    if (start > page_count) start = page_count - page_show_num
    initPageBar(start, 1)
  }

  function initPage(startPage, active) {
    let xhr = new XMLHttpRequest()

    xhr.open('GET', baseUrl + '/manage/images')
    xhr.withCredentials = true
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send()
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          images_count = xhr.responseText
          page_count = parseInt(images_count / page_size)
          if (images_count % page_size > 0) page_count += 1
          if (page_count == 0) page_count = 1
          initPageBar(startPage, active)
        } else if (xhr.status == 400) {
          // window.location.href = '/'
        }
      }
    }
  }

  function initPageBar(start, active) {
    let pagebar = document.getElementById('pagebar')
    let residue_page_num = page_count - (start - 1)
    if (active > residue_page_num) active = residue_page_num
    if (active == 0) {
      start = start - page_show_num
      residue_page_num = 9
      active = 9
    }
    start_num = start
    let html = '<li id="prev" onclick="pageBarPrev();"><a>&laquo;</a></li>'
    for (let i = 0; i < residue_page_num; i++) {
      if (i == page_show_num) break
      if (i == active - 1) {
        html += `<li><a class="active" onclick="selectPage(this);">${
          start + i
        }</a></li>`
      } else {
        html += `<li><a onclick="selectPage(this);">${start + i}</a></li>`
      }
    }
    html += '<li id="next" onclick="pageBarNext();"><a>&raquo;</a></li>'
    if (pagebar) pagebar.innerHTML = html
    document.getElementById('prev').style.visibility =
      start == 1 ? 'hidden' : 'visible'
    document.getElementById('next').style.visibility =
      residue_page_num > page_show_num ? 'visible' : 'hidden'
    current_page = document.getElementsByClassName('active')[0]
    setPage()
  }

  function deleteImages(image_nums, startPage, active) {
    image_nums = image_nums.map(image_nums => image_nums).join(',')
    let xhr = new XMLHttpRequest()
    xhr.open('GET', `${baseUrl}/manage/delete/images?image_nums=${image_nums}`)
    xhr.withCredentials = true
    xhr.send()
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          initPage(startPage, active)
        } else if (xhr.status == 400) {
          // window.location.href = '/'
        }
      }
    }
  }

  function deleteSelectAction() {
    let del = document.getElementById('deleteselect')
    del.onclick = function () {
      let image_nums = []
      let images = document.getElementsByClassName('image')
      for (let i = 0; i < images.length; i++) {
        if (
          images[i].style.zIndex == 1 &&
          images[i].style.visibility == 'visible'
        ) {
          image_nums.push(getImageNum(images[i]))
        }
      }
      if (image_nums.length > 0)
        deleteImages(
          image_nums,
          start_num,
          current_page.innerHTML - start_num + 1
        )
    }
  }

  function closeAction() {
    let win = document.getElementById('win')
    let close = document.getElementById('close')
    close.onclick = function () {
      win.style.zIndex = -1
      win.style.visibility = 'hidden'
    }
  }

  function imageSelectStyle(image) {
    image.style.transform = 'scale(1.05)'
    image.style.transition = 'all 0.2s linear'
    image.style.zIndex = 1
    image.style.boxShadow = '0px 0px 10px #555'
  }

  function imageHoverStyle(image) {
    image.style.transform = 'scale(1.05)'
    image.style.transition = 'all 0.2s linear'
    image.style.zIndex = 2
    image.style.boxShadow = '0px 0px 10px #555'
  }

  function imageNormalStyle(image) {
    image.style.transform = 'scale(1)'
    image.style.transition = 'all 0.2s linear'
    image.style.boxShadow = ''
    image.style.zIndex = 0
  }

  function getImageNum(image) {
    return (
      parseInt(image.children[0].id.substring(5)) +
      (current_page.innerHTML - 1) * page_size
    )
  }

  function imageAction() {
    document.getElementById('images').oncontextmenu = function () {
      return false
    }
    let images = document.getElementsByClassName('image')
    for (let i = 0; i < images.length; i++) {
      images[i].onmouseout = function () {
        if (this.style.zIndex == 1) return
        imageNormalStyle(this)
      }
      images[i].onmouseover = function () {
        if (this.style.zIndex == 1) return
        imageHoverStyle(this)
      }
      images[i].onmousedown = function (e) {
        if (e.button == 2) {
          let win = document.getElementById('win')
          win.onmousedown = function (e) {
            e.stopPropagation()
          }
          win.style.zIndex = 999
          win.style.visibility = 'visible'
          getImage(getImageNum(this), 1, 'show')
          e.stopPropagation()
        }
        if (e.button == 0) {
          if (this.style.zIndex == 1) {
            imageNormalStyle(this)
          } else {
            imageSelectStyle(this)
          }
        }
      }
      images[i].oncontextmenu = function () {
        return false
      }
    }
  }

  function initCanvas(canvas, result) {
    let image = new Image()
    let div = canvas?.parentElement
    if (div) {
      // div.style.visibility = 'visible'
      // div.style.display = 'block'
      image.src = result.image
      image.onload = function () {
        let height
        let width
        let rate
        let resize = 0
        if (
          (div.clientHeight / image.height) * image.width <=
          div.clientWidth
        ) {
          height = div.clientHeight
          width = (div.clientHeight / image.height) * image.width
          rate = (width / div.clientWidth) * 100
          canvas.style.height = '100%'
          canvas.style.width = `${rate}%`
          resize = height / image.height
        } else {
          width = div.clientWidth
          height = (div.clientWidth / image.width) * image.height
          rate = (height / div.clientHeight) * 100
          canvas.style.height = `${rate}%`
          canvas.style.width = '100%'
          resize = width / image.width
        }
        canvas.height = height
        canvas.width = width
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
        for (let model in models) {
          drawModelLabel(
            ctx,
            result.data[model],
            lineWidth[model],
            lineColor[model],
            fontSize[model],
            fontColor[model],
            resize
          )
        }
        // drawModelType(ctx, result.models, lineColor, 5);
      }
    }
  }

  function getImages(start, end, resize) {
    let images = document.getElementsByClassName('image')
    for (let i = 0; i < images.length; i++) {
      images[i].style.transform = 'scale(1)'
      images[i].style.transition = 'all 0.2s linear'
      images[i].style.boxShadow = ''
      images[i].style.zIndex = 0
      images[i].style.visibility = 'hidden'
    }
    for (let i = 1; start <= end; start++, i++) {
      getImage(start, resize, i)
    }
  }

  function getImage(image_num, resize, canvasId) {
    let xhr = new XMLHttpRequest()
    xhr.open(
      'GET',
      `${baseUrl}/manage/image?image_num=${image_num}&resize=${resize}`
    )
    xhr.withCredentials = true
    xhr.send()
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let result = JSON.parse(xhr.responseText)
          let canvas = document.getElementById('image' + canvasId)
          initCanvas(canvas, result)
        } else if (xhr.status == 400) {
          // window.location.href = '/'
        }
      }
    }
  }

  function getInfo() {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', `${baseUrl}/manage/info`)
    xhr.withCredentials = true
    xhr.send()
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let result = JSON.parse(xhr.responseText)
          setTotalNum(result.sum)
          setFaceNum(result.face)
          setPronNum(result.pron)
        } else if (xhr.status == 400) {
          // window.location.href = '/'
          console.error(xhr.responseText)
        }
      }
    }
  }

  function drawModelLabel(
    context,
    data,
    linewidth,
    lineColor,
    fontSize,
    fontColor,
    resize
  ) {
    for (let i = 0; i < data.length; i++) {
      let box = data[i].box
      let confidence = data[i].confidence
      let label = data[i].label
      drawLabel(
        context,
        box[0] * resize,
        box[1] * resize,
        box[2] * resize,
        box[3] * resize,
        linewidth,
        lineColor,
        label,
        confidence,
        fontSize,
        fontColor
      )
    }
  }

  function drawLabel(
    context,
    x1,
    y1,
    x2,
    y2,
    lineWidth,
    lineColor,
    text,
    confidence,
    fontSize,
    fontColor
  ) {
    let label = text + ' ' + confidence
    const extend = 4 + lineWidth / 2

    x1 = x1 < 5 * extend ? 5 * extend : x1
    y1 = y1 < 5 * extend ? 5 * extend : y1

    context.lineWidth = lineWidth
    context.strokeStyle = lineColor
    context.strokeRect(x1, y1, x2 - x1, y2 - y1)

    // context.fillStyle = lineColor;
    context.textAlign = 'start'
    context.textBaseline = 'bottom'

    context.font = `${fontSize}px bold 黑体`
    let width = context.measureText(label).width
    // context.fillRect(
    //   x1 - lineWidth / 2,
    //   y1 - fontSize - extend,
    //   width + extend,
    //   fontSize + extend
    // );

    context.fillStyle = fontColor
    context.fillText(label, x1, y1 - lineWidth / 2)
  }

  function drawModelType(context, models, colors, radius) {
    let y = radius
    let x = radius
    for (let model in models) {
      if (models[model]) {
        context.beginPath()
        context.arc(x, y, radius, 0, 360)
        context.fillStyle = colors[model]
        context.fill()
        context.closePath()
        x += radius * 2
      }
    }
  }
  const recoItems = [
    {
      id: 'face',
      title: '人脸识别',
      color: '#F43232',
    },
    {
      id: 'pron',
      title: '色情识别',
      color: '#FFA042',
    },
    // {
    //   id: "flag",
    //   title: "标识识别",
    //   color: "#3CC03C",
    // },
    // {
    //   id: "text",
    //   title: "文字识别",
    //   color: "#556DF7",
    // },
  ]
  const recoSelet = [
    {
      id: 'type',
      title: '识别类型',
      options: [
        {
          id: 'all',
          title: '全部',
        },
        {
          id: 'face',
          title: '人脸识别',
        },
        {
          id: 'pron',
          title: '色情识别',
        },
        // {
        //   id: "flag",
        //   title: "标识识别",
        // },
        // {
        //   id: "text",
        //   title: "文字识别",
        // },
      ],
    },
    // {
    //   id: 'level',
    //   title: '风险程度',
    //   options: [
    //     {
    //       id: 'low',
    //       title: '低',
    //     },
    //     {
    //       id: 'middle',
    //       title: '中',
    //     },
    //     {
    //       id: 'high',
    //       title: '高',
    //     },
    //   ],
    // },
  ]

  function handleChange(value) {
    setCurrentType(value)
    handleGetTotalPage(faceArray, pronArray, value).then(page => {
      getAll(getList(1, page))
    })
  }
  function formatter(value) {
    return `${value / 100}`
  }
  function onChange(pageNumber) {
    for (let i = 0; i < selectItem.length; i++) {
      selectItem[i] = false
    }
    document.getElementById('selectall').checked = false
    setCurrentPage(pageNumber)
    getAll(getList(pageNumber))
  }

  let handleGetTotalPage = (face, prod, type) => {
    return getToal(face, prod, type).then(function (page) {
      page = Number(page)
      let totalPage = page / 12
      setCurrentPage(1)
      setTotalNumber(page)
      if (totalPage % 1 == 0) {
        setTotalPage(totalPage)
      } else {
        setTotalPage((totalPage | 0) + 1)
      }
      return page
    })
  }

  useEffect(() => {
    handleGetTotalPage()
    getInfo()
  }, [])

  useEffect(() => {
    getAll(getList(1))
  }, [totalPage])

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(false)
    }, 100)

    document.body.style = ''
  }, [])

  // console.log(currentType, totalPage, totalNumber)

  let showCanvasByClick = number => {
    currentIndex = number
    setIsModalVisible(true)
    let modal = document.querySelector('.ant-modal-root')
    modal.style.display = 'block'

    let parent = document.getElementById('bigParent')
    parent.style.display = 'block'

    var big = document.getElementById('bigCanvas')

    let result = allResult[number]
    initCanvas(big, result)
  }
  let getSelectCount = () => {
    let currentNum = totalNumber - (currentPage - 1) * 12
    return selectItem
      .filter((item, i) => {
        return i < currentNum
      })
      .filter(item => {
        return item
      }).length
  }

  return (
    <div id="manage">
      <div className="nav-header">
        <Link to="/video">
          <div className="nav-btn">
            <img
              src={require('../../../assets/image/icon-arrow@2x.png')}
              alt="back"
              className="nav-back"
            />
            <span>检测结果</span>
          </div>
        </Link>
      </div>
      <div className="info-bar">
        <div className="infoItem">
          {recoItems.map(item => (
            <div
              className="info-item"
              id={item.id}
              key={item.id}
              style={{ background: '#fff' }}
            >
              <div className="title">{item.title}</div>
              <div className="icon" style={{ background: item.color }}></div>
            </div>
          ))}
        </div>
        <div className="infoSelet">
          {recoSelet.map(item => (
            <Select
              key={item.id}
              defaultValue={'all'}
              style={{ width: 207, height: 36 }}
              suffixIcon={
                <img
                  src={require('../../../assets/image/icon-arrow@2x.png')}
                  alt="select"
                  className="select-down"
                />
              }
              onChange={handleChange}
            >
              {item.options.map(optionItem => (
                <Option key={optionItem.id} value={optionItem.id}>
                  {optionItem.title}
                </Option>
              ))}
            </Select>
          ))}
          {currentType == 'all' ? (
            <></>
          ) : (
            <>
              <div className="info-slider">
                <span className="slider-title">阈值筛选</span>
                <span>0</span>

                {currentType == 'face' ? (
                  <Slider
                    range
                    value={faceArray}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={value => {
                      setFaceArray(value)
                    }}
                  />
                ) : (
                  <Slider
                    range
                    value={pronArray}
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={value => {
                      setPronArray(value)
                    }}
                  />
                )}

                <span>1</span>

                <Button
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    handleGetTotalPage(faceArray, pronArray).then(page => {
                      getAll(getList(1, page))
                    })
                  }}
                >
                  筛选
                </Button>
              </div>
            </>
          )}

          <div className="info-delete" onClick={deleteAllImages}>
            <img
              style={{ width: '20px', height: '20px' }}
              src={require('../../../assets/image/delete@2x.png')}
              alt="delete"
              className="delete-btn"
            />
          </div>
        </div>
      </div>
      <div className="info-data">
        <div className="big-canvas" id="bigParent" style={{ display: 'none' }}>
          <div className="close"></div>
        </div>
        <div className="data-mumber">
          <div className="data-mumber-title">检测统计</div>
          <div className="data-mumber-list">
            <div className="data-mumber-list-item">全部共：{totalNum}帧</div>
            <div className="data-mumber-list-item">人脸共：{faceNum}帧</div>
            <div className="data-mumber-list-item">色情共：{pronNum}帧</div>
          </div>
        </div>
        <div className="data-image">
          <div className="data-image-choose">
            <input
              type="checkbox"
              name="全选"
              id="selectall"
              onClick={e => {
                let array = selectItem.map(item => {
                  return e.target.checked
                })
                setSelectItem(array)
              }}
            />{' '}
            全选
            <span className="choosen-num">已选择{getSelectCount()}项内容</span>
            <Button
              type="link"
              onClick={() => {
                document.getElementById('selectall').checked = false
                let array = selectItem.map(item => {
                  return false
                })
                setSelectItem(array)
              }}
            >
              取消选择
            </Button>
          </div>
          <div className="data-image-list">
            <div className="imageCanvas">
              <div className="imageCanvas-item">
                <canvas id="image1"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    checked={selectItem[0]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[0] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(0)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image2"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    checked={selectItem[1]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[1] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(1)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image3"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    checked={selectItem[2]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[2] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(2)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image4"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    checked={selectItem[3]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[3] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(3)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image5"></canvas>

                <div className="fckIcon selectIcon">
                  <Checkbox
                    checked={selectItem[4]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[4] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(4)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image6"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[5]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[5] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(5)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image7"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[6]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[6] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(6)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image8"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[7]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[7] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(7)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image9"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[8]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[8] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(8)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image10"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[9]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[9] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(9)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image11"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[10]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[10] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(10)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
              <div className="imageCanvas-item">
                <canvas id="image12"></canvas>
                <div className="fckIcon selectIcon">
                  <Checkbox
                    key={Math.random()}
                    checked={selectItem[11]}
                    onChange={e => {
                      let array = [...selectItem]
                      array[11] = e.target.checked
                      setSelectItem(array)
                    }}
                  ></Checkbox>
                </div>
                <div
                  className="fckIcon"
                  onClick={() => {
                    showCanvasByClick(11)
                  }}
                >
                  <ArrowsAltOutlined />
                </div>
              </div>
            </div>
            {totalPage > 0 || totalNumber > 0 ? (
              <Pagination
                style={{ marginTop: '30px' }}
                showQuickJumper
                defaultCurrent={currentPage}
                total={totalNumber}
                pageSize={12}
                pageSizeOptions={['12']}
                onChange={onChange}
              />
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
        }}
        width={580}
        footer={null}
        id={'canvasModal'}
      >
        <canvas
          key="canvasBig"
          id="bigCanvas"
          width="530"
          height="400"
        ></canvas>

        <span
          className="switch-btn switch-prev"
          onClick={() => {
            const dataLen = allResult.length
            currentIndex = (currentIndex - 1 + dataLen) % dataLen
            showCanvasByClick(currentIndex)
          }}
        >
          &lt;
        </span>
        <span
          className="switch-btn switch-next"
          onClick={() => {
            const dataLen = allResult.length
            currentIndex = (currentIndex + 1 + dataLen) % dataLen
            showCanvasByClick(currentIndex)
          }}
        >
          &gt;
        </span>
      </Modal>
      {/* <div id="pagebox" >
        <ul className="pagination" id="pagebar"></ul>
      </div> */}
    </div>
  )
}
export default Manage
