import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import VideoAudit from './pages/videoAudit'
import LiveVideo from './pages/liveVideo'
import ImageAudit from './pages/imageAudit'
import Manage from './pages/videoAudit/manage'
import { ConfigProvider, Button } from 'antd' // 引入ConfigProvider全局化配置
import zhCN from 'antd/es/locale/zh_CN' // 引入中文包
import axios from 'axios'
import { baseUrl } from './server/baseUrl'
import cookie from 'react-cookies'
import './App.css'

function App() {
  const [path, setPath] = useState('/image')

  useEffect(() => {
    setPath(window.location.pathname)

    axios
      .get(baseUrl + '/cookei', {
        withCredentials: true,
      })
      .then(res => {
        console.log(res)
        if (res.status == 200) {
          // window.localStorage.setItem('userId', res.data)
          // cookie.save('user_id', res.data)
        }
      })
  }, [])

  useEffect(() => {}, [])

  return (
    <Router>
      <div className="App">
        <ConfigProvider locale={zhCN}>
          <header>
            <h1 className="title">
              <img src={require('./assets/image/logo.jpg')} alt="logo"></img>
              <span>智能内容审核系统</span>
            </h1>
          </header>
          <div className="content">
            <nav id="app-nav">
              <h2 className="nav-title">内容安全</h2>
              <ul
                onClick={() => {
                  setPath(window.location.pathname)
                }}
              >
                <Link to="/image">
                  <li
                    className={
                      path.includes('/image') || path == '/'
                        ? 'nav-item nav-item-active'
                        : 'nav-item'
                    }
                  >
                    <img
                      src={require(path.includes('/image')
                        ? './assets/image/image-icon-active@2x.png'
                        : './assets/image/image-icon@2x.png')}
                      alt="图片审核"
                    ></img>
                    <span>图片审核</span>
                  </li>
                </Link>
                <Link to="/video">
                  <li
                    className={
                      path.includes('/video')
                        ? 'nav-item nav-item-active'
                        : 'nav-item'
                    }
                  >
                    <img
                      src={require(path.includes('/video')
                        ? './assets/image/video-icon-active@2x.png'
                        : './assets/image/video-icon@2x.png')}
                      alt="视频审核"
                    ></img>
                    <span>视频审核</span>
                  </li>
                </Link>
                <Link to="/livevideo">
                  <li
                    className={
                      path.includes('/livevideo')
                        ? 'nav-item nav-item-active'
                        : 'nav-item'
                    }
                  >
                    <img
                      src={require(path.includes('/video')
                        ? './assets/image/video-icon-active@2x.png'
                        : './assets/image/video-icon@2x.png')}
                      alt="视频审核"
                    ></img>
                    <span>视频审核</span>
                  </li>
                </Link>
              </ul>
            </nav>
            <Route path="/image" exact component={ImageAudit}></Route>
            <Route path="/video" exact component={VideoAudit}></Route>
            <Route path="/livevideo" exact component={LiveVideo}></Route>
            <Route path="/" exact component={ImageAudit}></Route>
            <Route path="/video/manage" exact component={Manage}></Route>
          </div>
        </ConfigProvider>
      </div>
    </Router>
  )
}
export default App
