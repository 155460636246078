import axios from 'axios'

function getHost() {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'http://182.150.116.247:8001'
    case 'qa':
      return 'http://182.150.116.247:8001'
    case 'prod':
      return 'http://182.150.116.247:8001'
    default:
      return 'http://182.150.116.247:8001'
  }
}

const host = getHost()

export function getM3u8Frame() {
  return axios.get(`${host}/image`).then(res => {
    if (res.data) {
      return Promise.resolve(res.data)
    }
    return Promise.reject()
  })
}

export function switchM3u8FrameChannel(channel = 0) {
  return axios.post(`${host}/channel`, { channel }).then(res => {
    if (res.data) {
      return Promise.resolve(res.data)
    }
    return Promise.reject()
  })
}
