import axios from "axios";
import { baseUrl } from "./baseUrl";
//封装axios数据请求
function requestConfig(method, url, params, headers, body) {
  let config = {
    method: method,
    url: `${baseUrl}${url}`,
    headers: headers || {},
    timeout: 5000,
  };
  if (params && !body)
    /^(get|delete|patch)$/.test(method)
      ? (config.params = params)
      : (config.data = params);
  if (body) config.data = params;
  return new Promise((resolve, reject) => {
    axios(config).then(
      (res) => {
        resolve(res.data);
      },
      (err) => {
        let response = err.response;
        if (!response) {
          console.error("网络错误，请求数据失败");
          reject("err");
          return;
        }
        reject(response.status || "err");
      }
    );
  });
}
let http = {};
/**
 * @param {string} url 请求地址
 * @param {string} data 请求数据
 * @param {object} headers 请求头
 * @param {function} callback 回调函数
 */
http.get = (url, params, { headers, callback, body }) => {
  requestConfig("get", url, params, headers, body).then(
    (res) => {
      if (typeof callback === "function" && res) callback(res, undefined);
    },
    (err) => {
      if (typeof callback === "function" && err) callback(undefined, err);
    }
  );
};
http.post = async (url, data, { headers, callback }) => {
  requestConfig("post", url, data, headers).then(
    (res) => {
      if (typeof callback === "function" && res) callback(res, undefined);
    },
    (err) => {
      if (typeof callback === "function" && err) callback(undefined, err);
    }
  );
};
export default http;
